.p-datatable table {
    font-family: "Montserrat", 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}

.p-paginator,
.p-datatable .p-datatable-tbody>tr,
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-datatable-thead>tr>th {
    background: #2a323d;
}

.p-datatable .p-datatable-header {
    background: #2a323d;
}

.p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.6rem 0.5rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.p-datatable .p-datatable-loading-overlay {
    border-radius: 4px;
}

.p-datatable .p-column-header-content {
    padding: 0.2rem;
}

.p-paginator .p-paginator-current {
    font-size: 12px;
    border: none;
    color: #f3f3f3;
    height: 1rem;
    padding: 1rem;
}

.p-datatable .p-paginator-bottom {
    max-height: 3.2rem;
    border-width: 0 0 1px 0;
    border-radius: 4px;
}

.p-datatable .p-column-title {
    font-weight: 500;
    font-size: 12px;
    padding: 4px 0px;
}

.p-datatable .p-sortable-column:focus {
    box-shadow: none;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    border: none;
    color: #f3f3f3;
    min-width: 2rem;
    height: 2rem;
    margin: .143rem;
    border-radius: 50%;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    padding: 0.7rem;
}

.p-datatable-emptymessage {
    font-size: 12px;
}

.p-datatable .p-sortable-column.p-highlight {
    color: #fa9d6f;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #fa9d6f;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    color: #fa9d6f;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #fa9d6f;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: rgba(216, 147, 147, 0.16);
    color: #fa9d6f;
}

.p-button {
    background: #f68d2e;
    color: #fff;
}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
    background: #cd6e1b;
    color: #fff;
    border-color: transparent;
}

.p-button.p-button-outlined:enabled {
    color: #fa9d6f;
}

.p-button.p-button-outlined:enabled:hover {
    color: #cd6e1b;
    background: transparent;
}

.p-button.p-button-text {
    color: #fa9d6f;
}

.p-button.p-button-text:enabled:hover,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 0, 0, 0.1);
    color: #cd6e1b;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: rgba(216, 147, 147, 0.16);
    color: #fa9d6f;
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #cd6e1b;
}

.p-inputtext:enabled:focus {
    box-shadow: inset 0 0 0 1px #cd6e1b, inset 0 0 0 1px #cd6e1b, inset 0 0 0 1px #cd6e1b, inset 0 0 0 1px #cd6e1b;
}

.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #cd6e1b;
}

.p-inputtext {
    font-size: 14px;
    padding: 0.5rem 0.75rem;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 14px;
    padding: 0.5rem 0.75rem;
}

.p-column-filter-menu {
    margin-left: 5px;
}