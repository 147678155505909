.custom_select {
    padding: 0px !important;
}

.custom_select:focus-within {
    border-color: #c65218;
    box-shadow: 0 0 0 0.05rem #c6521880, 0 0 0 0.1rem #c6521840;
}

.custom_select.is-valid {
    padding-right: calc(1.5em + 0.25rem) !important;
}

.custom_select.is-valid:focus-within {
    border-color: #75b798 !important;
    box-shadow: 0 0 0 0.25rem #19875440;
}

.custom_select.is-invalid {
    padding-right: calc(1.5em + 0.25rem) !important;
}

.custom_select.is-invalid:focus-within {
    border-color: #ea868f !important;
    box-shadow: 0 0 0 0.25rem #dc354540;
}
